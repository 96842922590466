import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    focused: boolean;
}

const initialState: InitialState = {
    focused: true
};

export const {
    reducer: homeWelcomeReducer,
    actions: homeWelcomeActions
} = createSlice({
    name: 'homeWelcome',
    initialState,
    reducers: {
        setFocused(state, { payload }: PayloadAction<boolean>) {
            state.focused = payload;
        }
    }
});
