import * as Yup from 'yup';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCall } from '@apis/api';
import { FormRequestParams } from '@apis/form-request';
import { FormikHelpers } from 'formik';
import { deleteCookie, getCookie } from '@features/cookie';

interface InitialState {
    sendMailLoading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

export const initialValues = {
    name: '',
    phone: '',
    email: '',
    comment: ''
};

type FormFields = typeof initialValues;

interface Params {
    values: FormFields;
    actions: FormikHelpers<FormFields>;
}

interface ResponseData {
    data: {
        ok: boolean;
        error: {
            message: string;
        };
    };
    actions: FormikHelpers<FormFields>;
}

const initialState: InitialState = {
    sendMailLoading: 'idle'
};

export const SendPhoneSchema = Yup.object().shape({
    name: Yup.string().required('Обязательное поле'),
    phone: Yup.string().required('Обязательное поле'),
    email: Yup.string().email('Неверный формат').required('Обязательное поле'),
    comment: Yup.string().required('Обязательное поле')
});

export const { reducer: emailReducer, actions: emailActions } = createSlice({
    name: 'email',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(sendPhone.pending, (state) => {
            state.sendMailLoading = 'pending';
        });
        builder.addCase(sendPhone.fulfilled, (state, action) => {
            action.payload.actions.resetForm();
            [
                'utm_source',
                'utm_medium',
                'utm_campaign',
                'utm_content',
                'utm_term'
            ].forEach((utm) => {
                deleteCookie(utm);
            });
            state.sendMailLoading = 'fulfilled';
        });
        builder.addCase(sendPhone.rejected, (state) => {
            state.sendMailLoading = 'rejected';
        });
    }
});

export const sendPhone = createAsyncThunk<ResponseData, Params>(
    'email/sendPhone',
    async (params) => {
        const request_params: FormRequestParams = {
            first_name: params.values.name,
            phone: params.values.phone,
            email: params.values.email,
            comment: params.values.comment,
            from: typeof window !== 'undefined' ? window.location.href : '',
            utm_source: getCookie('utm_source'),
            utm_medium: getCookie('utm_medium'),
            utm_campaign: getCookie('utm_campaign'),
            utm_content: getCookie('utm_content'),
            utm_term: getCookie('utm_term')
        };

        const data = await apiCall.formRequest(request_params);

        return { data, actions: params.actions };
    }
);
