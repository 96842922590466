import { sendFormRequestAxios } from '@lib/axios';

export interface FormRequestParams {
    first_name: string;
    phone: string;
    email: string;
    comment: string;
    from: string;
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
    utm_content: string;
    utm_term: string;
}

export const formRequest = async (params: FormRequestParams): Promise<any> => {
    const res = await sendFormRequestAxios.post('/', params);
    return res.data;
};
