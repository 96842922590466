import { initializationReducer } from '@features/initialization/model';
import { sidebarReducer } from '@features/sidebar/model';
import { homeWelcomeReducer } from '@page-templates/home/templates/welcome/model';
import { combineReducers } from 'redux';
import { homeReducer } from '@page-templates/home/model';
import { globalStrapiReducer } from './global-strapi';
import { requestFormReducer } from '@features/request-form/model';
import { faqReducer } from '@page-templates/home/templates/questions/model';
import { emailReducer } from '@page-templates/home/templates/pci-dss/model';

export const rootReducer = combineReducers({
    sidebar: sidebarReducer,
    faq: faqReducer,
    homeWelcome: homeWelcomeReducer,
    initialization: initializationReducer,
    home: homeReducer,
    globalStrapi: globalStrapiReducer,
    requestForm: requestFormReducer,
    email: emailReducer
});

type rootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<rootReducerType>;
