import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    MappedStrapiImage,
    mapStrapiImage,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';
import { apiCall } from '@apis/api';
import { GlobalGetRes } from '@apis/global';
import { RootState } from '@store';

interface InitialState {
    footer: {
        rich_address: StrapiResponsiveRichTextEditorFromRes;
        rich_rules_text: StrapiResponsiveRichTextEditorFromRes;
        rich_public_offer_text: StrapiResponsiveRichTextEditorFromRes;
        rich_copyright: StrapiResponsiveRichTextEditorFromRes;
        commonQuestionsEmail: {
            id: number;
            title: string;
            href: string;
            link_text: string;
        };
        techSupportEmail: {
            id: number;
            title: string;
            href: string;
            link_text: string;
        };
        logosSprite: MappedStrapiImage;
    };
}

const initialState: InitialState = {
    footer: {
        rich_address: null,
        rich_copyright: null,
        rich_public_offer_text: null,
        rich_rules_text: null,
        commonQuestionsEmail: {
            id: null,
            title: null,
            href: null,
            link_text: null
        },
        techSupportEmail: {
            id: null,
            title: null,
            href: null,
            link_text: null
        },
        logosSprite: null
    }
};

export const { reducer: globalStrapiReducer } = createSlice({
    name: 'globalStrapi',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchCmsDataForGlobal.fulfilled,
            (state, { payload }) => {
                const { footer } = payload?.data?.attributes || {};

                if (footer) {
                    state.footer.commonQuestionsEmail =
                        footer.common_questions_email;
                    state.footer.logosSprite = mapStrapiImage(
                        footer.logos_sprite
                    );
                    state.footer.rich_address = footer.rich_address;
                    state.footer.rich_rules_text = footer.rich_rules_text;
                    state.footer.rich_copyright = footer.rich_copyright;
                    state.footer.rich_public_offer_text =
                        footer.rich_public_offer_text;
                    state.footer.techSupportEmail = footer.tech_support_email;
                }
            }
        );
    }
});

export const fetchCmsDataForGlobal = createAsyncThunk<GlobalGetRes>(
    'globalStrapi/fetchCmsDataForGlobal',
    async (_, { getState }) => {
        const {
            initialization: { lang }
        } = getState() as RootState;
        const resData = await apiCall.globalGet(lang);
        return resData;
    }
);
