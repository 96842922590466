interface CookieOptions {
    path?: string;
    domain?: string;
    maxAge?: number;
    expires?: Date;
    secure?: boolean;
    sameSite?: 'Strict' | 'Lax' | 'None';
}

export const setCookie = (
    name: string,
    value: string,
    options: CookieOptions = {}
): void => {
    const cookieValue = encodeURIComponent(value);
    const cookieName = encodeURIComponent(name);

    let cookieString = `${cookieName}=${cookieValue}`;

    if (options.maxAge) {
        cookieString += `; max-age=${options.maxAge}`;
    }

    if (options.expires) {
        cookieString += `; expires=${options.expires.toUTCString()}`;
    }

    if (options.path) {
        cookieString += `; path=${options.path}`;
    }

    if (options.domain) {
        cookieString += `; domain=${options.domain}`;
    }

    if (options.secure) {
        cookieString += `; secure`;
    }

    if (options.sameSite) {
        cookieString += `; samesite=${options.sameSite}`;
    }

    document.cookie = cookieString;
};

export const getCookie = (name: string): string | null => {
    const nameEQ = encodeURIComponent(name) + '=';
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            return decodeURIComponent(cookie.substring(nameEQ.length));
        }
    }
    return null;
};

export const deleteCookie = (
    name: string,
    path?: string,
    domain?: string
): void => {
    setCookie(name, '', { path, domain, maxAge: -1 });
};

export const hasCookie = (name: string): boolean => {
    return getCookie(name) !== null;
};
