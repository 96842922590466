import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    open: boolean;
}

const initialState: InitialState = {
    open: false
};

export const { reducer: sidebarReducer, actions: sidebarActions } = createSlice(
    {
        name: 'sidebar',
        initialState,
        reducers: {
            setOpen(state, { payload }: PayloadAction<boolean>) {
                state.open = payload;
            }
        }
    }
);
