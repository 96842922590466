import { strapiAxios } from '@lib';
import {
    StrapiImageFromRes,
    StrapiListItemFromRes,
    StrapiButton,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';
import { LightListItem } from '@lib/types';

interface Res {
    data: {
        id: 2;
        attributes: Partial<{
            createdAt: string;
            updatedAt: string;
            publishedAt: string;
            locale: string;
            hero: {
                id: number;
                title: StrapiResponsiveRichTextEditorFromRes;
                description: string;
                typewriter_new: LightListItem[];
                integrate_button: StrapiButton;
                details_button: StrapiButton;
                payment_form_img: StrapiImageFromRes;
            };
            integration: {
                id: number;
                title: string;
                cards: StrapiListItemFromRes[];
            };
            advantages: {
                id: number;
                cards: StrapiListItemFromRes[];
            };
            calculator: {
                id: number;
                percent: string;
                description: string;
                rich_percent: StrapiResponsiveRichTextEditorFromRes;
                rich_description: StrapiResponsiveRichTextEditorFromRes;
                img: StrapiImageFromRes;
            };
            how_it_works: {
                id: number;
                title: string;
                description: string;
                cards: StrapiListItemFromRes[];
            };
            security: {
                id: number;
                title: string;
                description_1: string;
                description_2: string;
                description_3: string;
                description_4: string;
                description_5: string;
            };
            partners: {
                id: number;
                title: string;
                cards: StrapiListItemFromRes[];
            };
        }>;
    };
}
export type HomepageGetRes = Res;

export const homepageGet = async (lang: string): Promise<Res> => {
    const res = await strapiAxios.get('/api/homepage', {
        params: { locale: lang }
    });
    return res.data;
};
