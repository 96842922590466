import { colors, pxRem } from '@lib';
import { media } from '@sanch941/lib';
import { createGlobalStyle } from 'styled-components';

export const Common = createGlobalStyle`

    a {
        color: inherit;
    }

    body {
        font-family: 'Ubuntu', sans-serif;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #fff;
        color: #22242D;
        line-height: 1.15;
    }

    #modal-root{
        position: relative;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }



    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .typewritter__cursor {
        background-color: #450ABE;
        width: 3px;
        height: ${pxRem(28)};
        position: relative;
        top: ${pxRem(5)};
        margin-left: ${pxRem(2)} ;
        display: inline-block;

        ${media.md} {
            height: ${pxRem(46)};
            width: 5px;
        }
    }

    .swiper-wrapper {
        align-items: center; /* add this will all relevant prefixes */
    }

    .op-0 {
        opacity: 0;
    }

    .input-range__track--active {
        background: ${colors.mainPurple};
    }

    .input-range__slider {
        background: ${colors.mainPurple};
        border: 1px solid ${colors.mainPurple}
    }

    .input-range__slider-container{
        z-index: 1;
    }

    .input-range__slider:before{
        width: 200%;
        height: 200%;
        top: -50%;
        position: absolute;
        left: -50%;
        content: ' ';
        z-index: 2;
    }

    .input-range__track--background{
        &:before{
            position: absolute;
            content: ' ';
            width: 100%;
            height: 1.5em;
            top: -1.5em;
            left: 0;
        }

        &:after{
            position: absolute;
            content: ' ';
            width: 100%;
            height: 1.5em;
            top: 100%;
            left: 0;
        }
    }

    .swiper-wrapper {
        -webkit-transition-timing-function:linear!important;
        -o-transition-timing-function:linear!important;
        transition-timing-function:linear!important;
    }
`;
