import axios from 'axios';

const baseURL = _DEV_
    ? process.env.DEV_BASE_URL || '/tp-local-api'
    : process.env.API_URL;

export const baseAxios = axios.create({
    baseURL
});

const strapiUrl = 'https://cms.tarlanpayments.kz';

export const strapiAxios = axios.create({
    baseURL: strapiUrl,
    headers: {
        Authorization:
            'Bearer 352f02f761d24a013d834ca4dc8b8870f5c6ca7232c245ecfae478cfa15eb0f223a00ab8ab46f211b1b046d94641fb6a262cffa7c999ccfb28cb1e6182ef364e73b6343c33dfe3039bf81497f2180f9d8576caef6ede6a2c26dc681aa436ba404fdb74a2ac106af8b8a2b44a3e8621c230a6f1bd0bcccc6b91270acc44c885db'
    }
});

strapiAxios.interceptors.request.use((config) => {
    config.params = {
        populate: 'deep',
        ...config.params
    };

    return config;
});

const sendMailURL = _DEV_
    ? 'https://api.maruwebdev.com/maxpay'
    : 'https://api.maruwebdev.com/maxpay';

export const sendMailAxios = axios.create({
    baseURL: sendMailURL
});

export const sendFormRequestAxios = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    withCredentials: false
});
