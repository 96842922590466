/* @refresh reset */
import { errorHandler } from '@lib';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    init: boolean;
    lang: string;
}

const initialState: InitialState = {
    init: false,
    lang: 'ru'
};

const { reducer, actions } = createSlice({
    name: 'initialization',
    initialState,
    reducers: {
        setInit(state, { payload }: PayloadAction<boolean>) {
            state.init = payload;
        },
        setLang(state, { payload }: PayloadAction<string>) {
            state.lang = payload;
        }
    }
});

export const initializationActions = actions;
export const initializationReducer = reducer;

export const initialize = createAsyncThunk(
    'initialization/initialize',
    async (params: any, { dispatch }) => {
        const { pathname, lang } = params;
        try {
            dispatch(actions.setInit(false));
            dispatch(actions.setLang(lang));

            const thunk = getThunkByPathname(pathname);
            await dispatch(thunk());
            // await dispatch(fetchCmsDataForGlobal());

            dispatch(actions.setInit(true));
        } catch (err) {
            errorHandler({ err });
        }
    }
);

const getThunkByPathname = (pathname: string) => {
    switch (pathname) {
        default:
            return () => () => ({});
    }
};
