export const navigationMenuList = [
    {
        to: '/',
        text: 'Главная'
    },
    {
        to: '/#advantages',
        text: 'Преимущества'
    },
    {
        to: '/#services',
        text: 'Услуги'
    },
    {
        to: '/#connection',
        text: 'Подключение'
    },
    {
        to: '/#faq',
        text: 'Вопросы и ответы'
    }
    // {
    //     to: '/#tariffs',
    //     text: 'Наши тарифы'
    // }
];
